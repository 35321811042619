<template>
    <template v-if="mode === 'resetPassword'">
        <template v-if="allowed">
            <Main :show-title="false">
                <Empty illustration="contracts">
                    <template #title>Gendan adgangskode</template>
                    <template #description>til din Selvbygger+ konto</template>
                </Empty>
                Er du kommet i tanke om din adgangskode?&nbsp;<router-link to="/login">Gå til log ind</router-link>
            </Main>
            <aside>
                <h1>Gendan adgangskode</h1>
                <Form @submit="resetPassword" :processing="processing" :message="message">
                    <fieldset>
                        <Input type="email" v-model="email" autocomplete="username email" v-show="false"/>
                        <Input type="password" placeholder="Ny adgangskode*" v-model="password" :required="true" autocomplete="new-password" ref="password" />
                        <Input type="password" placeholder="Bekræft ny adgangskode*" v-model="confirmPassword" :required="true" autocomplete="new-password"
                               @focus="invalidPasswordMessage = null" :data-invalid="invalidPasswordMessage" :show-invalid-message="!!invalidPasswordMessage" />
                        <ActionButton label="Gendan adgangskode"/>
                    </fieldset>
                </Form>
            </aside>
        </template>
        <Main v-else :show-title="false" style="grid-column: span 2; background: rgb(var(--white-color));">
            <Empty illustration="contracts">
                <template #title>Anmodning udløbet</template>
                <template #description>Din anmodning om at gendanne din adgangskode er udløbet</template>
            </Empty>
        </Main>
    </template>
</template>

<script>
import { useRoute } from 'vue-router';
import { auth } from '@/utils/firebase';
import Main from '@/components/Main.vue';
import Empty from '@/components/Empty.vue';
import Form from '@/components/Form.vue';
import Input from '@/components/inputs/Input.vue';
import ActionButton from '@/components/buttons/ActionButton.vue';
import { ref } from 'vue';

export default {
    name: 'Action',
    setup() {
        const route = useRoute();
        const { mode, oobCode } = route.query;
        const email = ref(null);
        const allowed = ref(true);

        (async () => {
            try {
                const response = await auth.checkActionCode(oobCode);
                email.value = response.data.email;
            } catch (err) {
                allowed.value = false;
            }
        })();

        return { mode, oobCode, email, allowed };
    },
    components: {
        Main,
        Empty,
        Form,
        Input,
        ActionButton,
    },
    data() {
        return {
            password: null,
            confirmPassword: null,
            invalidPasswordMessage: null,
            processing: false,
            message: null,
        };
    },
    methods: {
        checkConfirmPassword() {
            this.invalidPasswordMessage = (this.password !== this.confirmPassword) ? 'Adgangskode stemmer ikke overens' : null;
        },
        async resetPassword() {
            if (this.password !== this.confirmPassword) {
                this.checkConfirmPassword();
            } else {
                this.processing = true;

                try {
                    await auth.confirmPasswordReset(this.oobCode, this.password);
                    this.message = { text: 'Sådan, din adgangskode blev ændret. Du viderestilles nu til log ind skærmen.', error: false };
                    this.processing = false;
                    setTimeout(() => {
                        this.$router.push({ name: 'login' });
                    }, 3000);
                } catch (err) {
                    this.processing = false;

                    if (err.code === 'auth/weak-password') {
                        this.$refs.password.$el.setAttribute('data-invalid', 'Kodeord skal bestå af minimum 6 karakterer');
                        this.$refs.password.$el.classList.add('show-invalid');
                    } else {
                        this.message = { text: err.message, error: true };
                    }
                }
            }
        },
    },
};
</script>
